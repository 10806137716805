import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Loader,
  CardsSlider,
  Modal,
  MontenegroUnitPaymentPlan,
} from '@orascom/common-components';
import { ROUTES } from '../../api/routes';
import styles from './unit-details.module.scss';
import { Unit as UnitUtilities } from '../../utils/unit.utils';
import {
  CURRENCIES_ENUM,
  CountriesEnum,
  DESTINATION_SLUG,
  UnitDetails as UnitDetailsInterface,
  UnitPaymentTerms,
} from '@orascom/api-interfaces';
import { GoldButton, NavyAnchor } from '../../components/common/button/button';
import { AboutOrascom } from '../../components/common/about-orascom/about-orascom';
import NewsCard from '../../components/common/news-card/news-card';
import ReachUs from '../../components/common/reach-us/reach-us';
import UnitMasterPlan from '../../components/unit-masterplan/unit-masterplan';
import UnitFacilities from '../../components/unit-facilities/unit-facilities';
import UnitVideo from '../../components/unit-video/unit-video';
import UnitPaymentPlan from '../../components/unit-payment-plan/unit-payment-plan';
import ContactUsForm from '../../components/common/contact-us-form/contact-us-form';
import { UNIT_COMPARE_CONTEXT } from '../../contexts/unit-comparison.context';
import CompareButton from '../../components/common/compare-button/compare-button';
import MasterPlan from '../../components/master-plan/master-plan';
import { v4 as uuidv4 } from 'uuid';

import {
  analyticsDownloadBrochureCustomEvent,
  analyticsRequestSalesCallCustomEvent,
  analyticsReserveUnitCustomEvent,
} from '../../utils/analytics-events';
import UnitBreadcrumbs from './unit-breadcrumbs';
import UnitData from './unit-data';
import { showReservationButton } from '../../definitions/consts/envConstants';
import { Helmet } from 'react-helmet-async';
import getDocTitle from '../../utils/getDocTitle';
import SimilarUnits from '../../components/similar-units/similar-units';
import { USER_CONTEXT } from '../../contexts/user-context';
import { CurrencyContext } from '@orascom/utils';
import TalkToSalesModal from '../../components/common/talk-to-sales-modal/talk-to-sales-modal';

function UnitDetails() {
  const [inComparison, setInComparison] = useState(false);
  const [wishListed, setWishListed] = useState(false);
  const [unitDetails, setUnitDetails] = useState<UnitDetailsInterface>();
  const unitContext = useContext(UNIT_COMPARE_CONTEXT);
  const {
    isLoading: isLoadingCurrency,
    setDisableCurrencySetting,
    setActiveCurrency,
  } = useContext(CurrencyContext);
  const unitId = useParams<{ unitId: string }>().unitId;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showInterestModal, setShowInterestModal] = useState(false);

  const [paymentTermsError, setPaymentTermsError] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<UnitPaymentTerms[]>([]);
  const [loading, setLoading] = useState(true);
  const unitCountry = unitDetails?.project.destination.country.slug;
  const [selectedPaymentTerms, setSelectedPaymentTerms] =
    useState<UnitPaymentTerms>();
  const handleSelectPaymentTerms = (paymentTerm: UnitPaymentTerms) => {
    setSelectedPaymentTerms(paymentTerm);
  };
  const location = useLocation();
  const userContext = useContext(USER_CONTEXT);

  const handleCloseModal = () => {
    setShowModal(false);
    setShowInterestModal(false);
    document.body.style.overflow = '';
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (unitContext.units) {
      setInComparison(unitContext.units?.includes(Number(unitId)));
    }
  }, [unitContext, unitId]);

  useEffect(() => {
    if (unitId) {
      UnitUtilities.getUnitDetails(unitId)
        .then((res) => {
          setUnitDetails(res);

          if (res.is_available === false) {
            navigate(ROUTES.NotAvailableUnit.path, { replace: true });
          }

          if (res.is_user_saved) {
            setWishListed(true);
          }
          if (res.project.destination.slug === DESTINATION_SLUG.GOUNA) {
            setActiveCurrency(CURRENCIES_ENUM.USD);
            setDisableCurrencySetting({
              disabled: true,
              info: t(`elGounaCurrencyDisclaimer`),
            });
          }
        })
        .catch((err) => {
          if (err.status === 404) {
            navigate(ROUTES.NotAvailableUnit.path, { replace: true });
          }
        });
    }

    return () =>
      setDisableCurrencySetting({
        disabled: false,
        info: '',
      });
  }, [unitId]);

  useEffect(() => {
    if (unitId && unitCountry === CountriesEnum.MONTENEGRO) {
      UnitUtilities.getUnitPayments(unitId)
        .then((terms) => {
          setPaymentTerms(terms);
          setLoading(false);
        })
        .catch(() => {
          setPaymentTermsError(true);
        });
    }
  }, [unitId, unitCountry]);

  if (!unitDetails || isLoadingCurrency) {
    return <Loader />;
  }
  const unitDocTitle = getDocTitle(unitDetails.name);

  const handleShowModal = () => {
    setShowModal(true);
    document.body.style.overflow = 'hidden';
    analyticsRequestSalesCallCustomEvent({
      userId: userContext.user?.id.toString(),
      timestamp: Date.now().toString(),
      portal: 'Shopper',
      pageName: location.pathname,
      country: unitDetails.project.destination.country.slug,
      destination: unitDetails.project.destination.slug,
    });
  };

  return (
    <div className={styles['unit-details']}>
      <Helmet>
        <title>{unitDocTitle}</title>
        <meta property="og:title" content={unitDocTitle} />
        {/* TODO: replace with unit description when the backend is ready */}
        <meta name="description" content={unitDetails.name} />
        <meta property="og:description" content={unitDetails.name} />
      </Helmet>
      <CompareButton />
      <div className={`${styles['header-headings']} container`}>
        <UnitBreadcrumbs destination={unitDetails.project.destination} />
      </div>
      <UnitData
        setWishListed={setWishListed}
        wishListed={wishListed}
        unitDetails={unitDetails}
        inComparison={inComparison}
        selectedPaymentTerms={selectedPaymentTerms}
        setShowInterestModal={setShowInterestModal}
        setShowModal={setShowModal}
        unitId={unitId}
      />
      {unitDetails.masterplans.length > 0 && (
        <UnitMasterPlan
          masterPlans={unitDetails.masterplans}
          unitName={unitDetails.name}
        />
      )}
      {unitDetails.video && (
        <UnitVideo
          unitVideo={unitDetails.video}
          unitVideoThumbnail={unitDetails.video_thumbnail}
        />
      )}
      {unitDetails.masterplan_image && (
        <MasterPlan
          title="Masterplan"
          subTitle={`The Art Of ${unitDetails.project.name}`}
          subDescription={
            unitDetails.masterplan_description ??
            unitDetails.project.destination.masterplan_description
          }
          image={unitDetails.masterplan_image}
          unitName={unitDetails.name}
        />
      )}
      {unitDetails.facilities.length ? (
        <UnitFacilities unitFacilities={unitDetails.facilities} />
      ) : null}
      <div className={styles['about-unit']}>
        <AboutOrascom
          title={t('locationDetails')}
          subTitle={`${t('about')} ${unitDetails.project.name}`}
          description={unitDetails.project.description}
          showAboutButton={true}
          buttonText={`${t('moreAbout')} ${
            unitDetails.project.destination.name
          }`}
          buttonLink={ROUTES.Destination.getPath?.(
            unitDetails.project.destination.slug
          )}
          imageFirst
        />
      </div>
      {unitCountry === CountriesEnum.MONTENEGRO ? (
        loading ? (
          <Loader />
        ) : paymentTermsError ? (
          <>Payment plan not found</>
        ) : (
          <div className={`container`}>
            <MontenegroUnitPaymentPlan
              unitDetails={unitDetails}
              paymentTerms={paymentTerms}
              handleSelectPaymentTerms={handleSelectPaymentTerms}
              selectPaymentTerms={selectedPaymentTerms}
            />
            {showReservationButton && (
              <div className={styles['reserve-button']}>
                <GoldButton
                  asLink
                  to={ROUTES.ReserverProperty.getPath?.(unitDetails?.id)}
                  onClick={() => {
                    analyticsReserveUnitCustomEvent({
                      position: 'bottom',
                      unitId: unitDetails?.id.toString(),
                    });
                  }}
                >
                  {t('reserveUnit')}
                </GoldButton>
              </div>
            )}
          </div>
        )
      ) : (
        <UnitPaymentPlan
          handleSelectPaymentTerms={handleSelectPaymentTerms}
          unitCountry={unitCountry}
          unitDetails={unitDetails}
        />
      )}
      {unitDetails.addons.length > 0 && (
        <div className={`${styles['addons']}`}>
          <div className={`${styles['headers']}`}>
            <p className="orascom__title">{t('unitAddons')}</p>
            <h2 className="orascom__sub-title">{t('enhanceUnit')}</h2>
            <p className="orascom__title">{t('unitAddonsDesc')}</p>
          </div>
          <CardsSlider slidesPerView={3.5}>
            {unitDetails.addons.map((addon) => (
              <NewsCard
                key={uuidv4()}
                image={addon.image}
                title={addon.title}
              />
            ))}
          </CardsSlider>
        </div>
      )}

      <SimilarUnits
        price={unitDetails.dollar_price}
        unitId={unitDetails.id}
        designType={unitDetails.design_type}
      />

      <ReachUs
        description={t('findWaysToEnjoyResidentialLife')}
        primaryButton={
          <GoldButton onClick={handleShowModal}>
            {t('requestSalesCall')}
          </GoldButton>
        }
        secondaryButton={
          unitDetails.brochure ||
          unitDetails.project.brochure ||
          unitDetails.project.destination.brochure ? (
            <NavyAnchor
              href={
                unitDetails.brochure
                  ? unitDetails.brochure
                  : unitDetails.project.brochure
                  ? unitDetails.project.brochure
                  : unitDetails.project.destination.brochure
              }
              download={`${unitDetails.unit_type.name} brochure`}
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                analyticsDownloadBrochureCustomEvent({
                  userId: userContext.user?.id.toString(),
                  timestamp: Date.now().toString(),
                  portal: 'Shopper',
                  pageName: location.pathname,
                  country: unitDetails.project.destination.country.slug,
                  destination: unitDetails.project.destination.slug,
                  unit: unitDetails.id.toString(),
                })
              }
            >
              {t('downloadBrochure')}
            </NavyAnchor>
          ) : null
        }
      />

      <TalkToSalesModal show={showModal} onClose={handleCloseModal} />
      <Modal
        show={showInterestModal}
        onClose={handleCloseModal}
        title={t('submitYourInterest')}
      >
        <ContactUsForm
          title={t('leaveYourContactInfo')}
          destination={unitDetails.project.destination.slug}
          country={unitDetails.project.destination.country.id}
          unitId={unitDetails.id}
          interestForm
          onCloseModal={handleCloseModal}
        />
      </Modal>
    </div>
  );
}

export default UnitDetails;
