import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './destination-location.module.scss';
import { Location } from '@orascom/api-interfaces';
import { formatLocationInfoItem } from '../../utils/common.utils';
import { ROUTES } from '../../api/routes';
import { analyticsDownloadDestinationBrochureCustomEvent } from '../../utils/analytics-events';
import { CommonEventParameters } from '@orascom/utils';
import { USER_CONTEXT } from '../../contexts/user-context';

/* eslint-disable-next-line */
export interface DestinationLocationProps {
  locationTitle: string;
  locationDescription: string;
  locationImage: string;
  locations: Location[];
  masterPlanTitle: string;
  masterPlanSubTitle: string;
  masterPlanSubDescription: string;
  masterPlanImage: string;
  masterPlanBrochure: string;
  destinationSlug: string;
}

export function DestinationLocation(props: Readonly<DestinationLocationProps>) {
  const { t } = useTranslation();
  const {
    locationTitle,
    locationDescription,
    locationImage,
    locations,
    masterPlanTitle,
    masterPlanSubTitle,
    masterPlanSubDescription,
    masterPlanImage,
    masterPlanBrochure,
  } = props;

  const userContext = useContext(USER_CONTEXT);

  const analyticsEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Broker',
    pageName: location.pathname,
    destination: props.destinationSlug,
  };

  return (
    <>
      <div className={`${styles['destination-location']} container`}>
        <div className={styles['destination-location__image']}>
          <img src={locationImage} alt="" loading="lazy" />
        </div>
        <div className={styles['destination-location__info']}>
          <h1
            className={`${styles['destination-location__info-title']} orascom__title`}
          >
            {t('howToFindUs')}
          </h1>
          <h2
            className={`${styles['destination-location__info-sub-title']} orascom__sub-title`}
          >
            {locationTitle}
          </h2>
          <p className={styles['destination-location__info-desc']}>
            {locationDescription}
          </p>
          <div className={styles['destination-location__image--responsive']}>
            <img src={locationImage} alt="" loading="lazy" />
          </div>
          <div className={styles['destination-location__info-details']}>
            {locations?.map((location) => {
              return (
                <div
                  className={styles['destination-location__info-details-item']}
                  key={location.id}
                >
                  <p className="orascom__sub-title">
                    {formatLocationInfoItem(location.time, location.unit)}
                  </p>
                  <p>{`${t('from')} ${location.name}`}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={`${styles['destination-master-plan']} container`}>
        <div className={styles['destination-master-plan__info']}>
          <h1
            className={`${styles['destination-master-plan__info-title']} orascom__title`}
          >
            {masterPlanSubTitle}
          </h1>
          <h2
            className={`${styles['destination-master-plan__info-sub-title']} orascom__sub-title`}
          >
            {masterPlanTitle}
          </h2>
          <p className={styles['destination-master-plan__info-desc']}>
            {masterPlanSubDescription}
          </p>
          <div className={styles['destination-master-plan__image--responsive']}>
            <img src={masterPlanImage} alt="" loading="lazy" />
          </div>
          {props.destinationSlug === 'lustica-bay' ? (
            <Link
              to={ROUTES['InteractiveMasterplan'].path}
              className={`btn btn--navy ${styles['destination-master-plan__info-button']}`}
            >
              {t('viewInteractiveMap')}
              <div className="white" />
            </Link>
          ) : (
            <a
              href={masterPlanBrochure}
              download
              target="_blank"
              rel="noreferrer"
              className={`btn btn--navy ${styles['destination-master-plan__info-button']}`}
              onClick={() =>
                analyticsDownloadDestinationBrochureCustomEvent(
                  analyticsEventParams
                )
              }
            >
              {t('downloadBrochure')}
              <div className="white" />
            </a>
          )}
        </div>
        <div className={styles['destination-master-plan__image']}>
          <img src={masterPlanImage} alt="" loading="lazy" />
        </div>
      </div>
    </>
  );
}

export default DestinationLocation;
